var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "vs-popup",
        {
          staticClass: "popup-merge",
          attrs: {
            title: "Birleştir",
            active: _vm.active,
            buttonCloseHidden: ""
          },
          on: {
            "update:active": function($event) {
              _vm.active = $event
            },
            close: _vm.close
          }
        },
        [
          _c("div", { staticClass: "modal-body " }, [
            _c(
              "div",
              { staticClass: "space-y-2" },
              [
                _c("label", { staticClass: "text-sm opacity-75 " }, [
                  _vm._v("Birleştirilecek başlığı seçiniz.")
                ]),
                _c(
                  "v-select",
                  {
                    staticClass: "mb-4 md:mb-0 select-topic",
                    attrs: {
                      options: _vm.checkedList,
                      reduce: function(item) {
                        return item.id
                      },
                      label: "topic",
                      clearable: false
                    },
                    model: {
                      value: _vm.selectedOption,
                      callback: function($$v) {
                        _vm.selectedOption = $$v
                      },
                      expression: "selectedOption"
                    }
                  },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "no-options" }, slot: "no-options" },
                      [_vm._v("Sonuç bulunamadı")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "action-buttons text-right mt-8" },
              [
                _c(
                  "vs-button",
                  { attrs: { type: "flat" }, on: { click: _vm.close } },
                  [_vm._v("\n        Vazgeç\n      ")]
                ),
                _c(
                  "vs-button",
                  { staticClass: "ml-4", on: { click: _vm.submit } },
                  [_vm._v("\n        Birleştir\n      ")]
                )
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }