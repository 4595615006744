var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: {
            multiple: false,
            stripe: "",
            sst: true,
            "max-items": 50,
            data: _vm.listData,
            noDataText: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _vm.isMerging
                            ? _c("vs-td", { attrs: { id: "check" } }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: tr.checked,
                                      expression: "tr.checked"
                                    }
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "test",
                                    id: ""
                                  },
                                  domProps: {
                                    checked: Array.isArray(tr.checked)
                                      ? _vm._i(tr.checked, null) > -1
                                      : tr.checked
                                  },
                                  on: {
                                    change: [
                                      function($event) {
                                        var $$a = tr.checked,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                tr,
                                                "checked",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                tr,
                                                "checked",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(tr, "checked", $$c)
                                        }
                                      },
                                      _vm.handleCheckbox
                                    ]
                                  }
                                })
                              ])
                            : _vm._e(),
                          _c("vs-td", { attrs: { id: "status" } }, [
                            _c("div", { staticClass: "flex flex-wrap w-16" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center"
                                },
                                [
                                  _c(
                                    "vx-tooltip",
                                    {
                                      staticClass: "state-tooltip",
                                      attrs: { text: _vm.states[tr.state].text }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-5 h-5 text-sm rounded-full text-white font-bold flex items-center justify-center leading-none",
                                          class: _vm.states[tr.state].color
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.states[tr.state].shortText
                                              ) +
                                              "\n                  "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              tr.locked
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center"
                                    },
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass: "state-tooltip",
                                          attrs: { text: "Kilitli" }
                                        },
                                        [
                                          _c("vs-icon", {
                                            attrs: {
                                              icon: "lock",
                                              size: "small"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              tr.picked
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center"
                                    },
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass: "state-tooltip",
                                          attrs: { text: "Seçmece" }
                                        },
                                        [
                                          _c("vs-icon", {
                                            attrs: {
                                              icon: "bookmark",
                                              size: "small"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              tr.pinned
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center"
                                    },
                                    [
                                      _c(
                                        "vx-tooltip",
                                        {
                                          staticClass: "state-tooltip",
                                          attrs: { text: "Sabit" }
                                        },
                                        [
                                          _c("vs-icon", {
                                            attrs: {
                                              icon: "flag",
                                              size: "small"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ]),
                          _c(
                            "vs-td",
                            { attrs: { id: "topic", data: tr.topic } },
                            [
                              _c("div", { staticClass: "flex items-center" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "topic__image-wrapper flex-shrink-0 img-loader flex mr-2 relative",
                                    attrs: { id: "img-" + tr.id }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "topic__image w-16 h-16 bg-gray-300 rounded overflow-hidden flex items-center justify-center"
                                      },
                                      [
                                        tr.thumbnailUrl
                                          ? _c("img", {
                                              staticClass:
                                                "rounded p-0 w-16 h-16 object-cover",
                                              attrs: {
                                                src: _vm.getImageUrl(
                                                  tr.thumbnailUrl,
                                                  "75x75"
                                                )
                                              }
                                            })
                                          : _c("vs-icon", {
                                              staticClass: "text-gray-600",
                                              attrs: {
                                                size: "large",
                                                icon: "insert_photo"
                                              }
                                            })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "topic__image-hover absolute bg-gray-300 flex items-center justify-center w-full h-full rounded cursor-pointer"
                                      },
                                      [
                                        _c("vs-icon", {
                                          staticClass: "text-gray-900",
                                          attrs: {
                                            size: "large",
                                            icon: "insert_photo"
                                          }
                                        }),
                                        _c("input", {
                                          staticClass: "hidden",
                                          attrs: {
                                            type: "file",
                                            accept: "image/*"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.handleImageAdd(
                                                $event,
                                                tr.id
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "topic__text break-word font-medium text-black overflow-auto max-w-sm min-w-64",
                                    on: { click: _vm.showTopicEntry }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(tr.topic) +
                                        "\n              "
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass: "max-w-64 min-w-32 break-word",
                              attrs: { id: "username" }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(tr.user && tr.user.username) +
                                  "\n          "
                              )
                            ]
                          ),
                          false
                            ? _c(
                                "vs-td",
                                {
                                  staticClass: "w-24",
                                  attrs: { id: "onedioId" }
                                },
                                [
                                  _c("p", { staticClass: "font-medium" }, [
                                    _vm._v(_vm._s(tr.onedioId))
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _c("vs-td", { attrs: { id: "createDate" } }, [
                            _c("p", { staticClass: "font-medium w-24" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      tr.createDate,
                                      "DD-MM-YYYY"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.createDate, "HH:mm")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c("vs-td", { attrs: { id: "updateDate" } }, [
                            _c("p", { staticClass: "font-medium w-24" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      tr.updateDate,
                                      "DD-MM-YYYY"
                                    )
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.updateDate, "HH:mm")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          false
                            ? _c("vs-td", { attrs: { id: "publishDate" } }, [
                                _c(
                                  "p",
                                  { staticClass: "font-medium w-24" },
                                  [
                                    tr.publishDate
                                      ? [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  tr.publishDate,
                                                  "DD-MM-YYYY"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm._f("moment")(
                                                  tr.publishDate,
                                                  "HH:mm"
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                -\n              "
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ])
                            : _vm._e(),
                          _c("vs-td", { key: tr.id, attrs: { id: "action" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center space-x-2 justify-end"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex space-x-1" },
                                  [
                                    !_vm.isMerging
                                      ? _c(
                                          "vx-tooltip",
                                          { attrs: { text: "Diolari Gör" } },
                                          [
                                            _c("vs-button", {
                                              staticClass: "btn btn--action",
                                              attrs: {
                                                color: "primary",
                                                size: "small",
                                                type: "gradient",
                                                icon: "view_list"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.gotoDio(
                                                    $event,
                                                    tr.id
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.isMerging
                                      ? _c(
                                          "vx-tooltip",
                                          { attrs: { text: "Düzenle" } },
                                          [
                                            _c("vs-button", {
                                              staticClass: "btn btn--action",
                                              attrs: {
                                                color: "secondary",
                                                size: "small",
                                                type: "gradient",
                                                icon: "edit"
                                              },
                                              on: { click: _vm.editTopic }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.isMerging &&
                                    tr.state.includes("waiting")
                                      ? _c(
                                          "vx-tooltip",
                                          { attrs: { text: "Onayla" } },
                                          [
                                            _c("vs-button", {
                                              staticClass: "btn btn--action",
                                              attrs: {
                                                color: "success",
                                                size: "small",
                                                type: "gradient",
                                                icon: "check_circle"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleApproveTopic(
                                                    $event,
                                                    tr.id
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "vs-dropdown",
                                  {
                                    staticClass:
                                      "action-button action-button--topic cursor-pointer rounded border border-solid border-gray-300 bg-gray-100 hover:bg-gray-300 px-1",
                                    attrs: { "vs-trigger-click": "" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex items-center" },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            icon: "MoreHorizontalIcon",
                                            svgClasses: "more-icon"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "vs-dropdown-menu",
                                      { staticClass: "w-56" },
                                      [
                                        !_vm.isMerging &&
                                        _vm.filterType === "picked"
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleUpPickedTopic(
                                                      $event,
                                                      tr.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center text-base"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "-ml-3 flex items-center justify-center w-10"
                                                      },
                                                      [
                                                        _c("vs-icon", {
                                                          attrs: {
                                                            icon:
                                                              "arrow_upward",
                                                            size: "small"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("span", [
                                                      _vm._v("Yukarı Çıkar")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isMerging
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleLockTopic(
                                                      $event,
                                                      tr.id,
                                                      tr.locked
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center text-base"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "-ml-3 flex items-center justify-center w-10"
                                                      },
                                                      [
                                                        _c("vs-icon", {
                                                          attrs: {
                                                            icon: tr.locked
                                                              ? "lock"
                                                              : "lock_open",
                                                            size: "small"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          tr.locked
                                                            ? "Kilidi Kaldır"
                                                            : "Kilitle"
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isMerging
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handlePickTopic(
                                                      $event,
                                                      tr.id,
                                                      tr.picked
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center text-base"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "-ml-3 flex items-center justify-center w-10"
                                                      },
                                                      [
                                                        _c("vs-icon", {
                                                          attrs: {
                                                            icon: tr.picked
                                                              ? "bookmark"
                                                              : "bookmark_border",
                                                            size: "small"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          tr.picked
                                                            ? "Seçmece'den Çıkar"
                                                            : "Seçmece'ye Ekle"
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isMerging
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handlePinTopic(
                                                      $event,
                                                      tr.id,
                                                      tr.pinned
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center text-base"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "-ml-3 flex items-center justify-center w-10"
                                                      },
                                                      [
                                                        _c("vs-icon", {
                                                          attrs: {
                                                            icon: tr.pinned
                                                              ? "flag"
                                                              : "outlined_flag",
                                                            size: "small"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          tr.pinned
                                                            ? "Sabitten Çıkar"
                                                            : "Sabitle"
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isMerging &&
                                        !tr.state.includes("deleted")
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    _vm.showInstagramDialog = true
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center text-base"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "-ml-3 flex items-center justify-center w-10"
                                                      },
                                                      [
                                                        _c("vs-icon", {
                                                          attrs: {
                                                            icon:
                                                              "icon-instagram",
                                                            "icon-pack":
                                                              "feather"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("span", [
                                                      _vm._v("Instagram'a Ekle")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isMerging &&
                                        !_vm.$store.state.route.query.search
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    _vm.showSendMessageDialog = true
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center text-base"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "-ml-3 flex items-center justify-center w-10"
                                                      },
                                                      [
                                                        _c("vs-icon", {
                                                          attrs: {
                                                            icon: "icon-send",
                                                            "icon-pack":
                                                              "feather"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("span", [
                                                      _vm._v("Mesaj Gönder")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "vs-dropdown-item",
                                          {
                                            on: {
                                              click: function($event) {
                                                _vm.handleMergeTopic(
                                                  $event,
                                                  tr,
                                                  _vm.mergeList.find(function(
                                                    item
                                                  ) {
                                                    return item.id === tr.id
                                                  })
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "flex items-center text-base"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "-ml-3 flex items-center justify-center w-10"
                                                  },
                                                  [
                                                    _c("vs-icon", {
                                                      attrs: {
                                                        icon: _vm.mergeList.find(
                                                          function(item) {
                                                            return (
                                                              item.id === tr.id
                                                            )
                                                          }
                                                        )
                                                          ? "undo"
                                                          : "playlist_add",
                                                        size: "small"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.mergeList.find(
                                                        function(item) {
                                                          return (
                                                            item.id === tr.id
                                                          )
                                                        }
                                                      )
                                                        ? "Birleştir'den Çıkar"
                                                        : "Birleştir'e Ekle"
                                                    )
                                                  )
                                                ])
                                              ]
                                            )
                                          ]
                                        ),
                                        !_vm.isMerging &&
                                        !tr.state.includes("deleted")
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    ;(_vm.complaintMessageDialog = true),
                                                      _vm.deleteEntry(
                                                        $event,
                                                        tr.topic,
                                                        tr.id,
                                                        tr.userId
                                                      )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center text-base"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "-ml-3 flex items-center justify-center w-10"
                                                      },
                                                      [
                                                        _c("vs-icon", {
                                                          attrs: {
                                                            icon:
                                                              "icon-trash-2",
                                                            "icon-pack":
                                                              "feather"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("span", [_vm._v("Sil")])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        !_vm.isMerging &&
                                        tr.state.includes("deleted")
                                          ? _c(
                                              "vs-dropdown-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    return _vm.handleRestoreTopic(
                                                      $event,
                                                      tr.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "flex items-center text-base"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "-ml-3 flex items-center justify-center w-10"
                                                      },
                                                      [
                                                        _c("vs-icon", {
                                                          attrs: {
                                                            icon:
                                                              "settings_backup_restore",
                                                            size: "small"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c("span", [
                                                      _vm._v("Geri Getir")
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "flex items-center justify-between w-full",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _vm.$store.state.route.query.tab === "merge"
                ? _c(
                    "vs-button",
                    {
                      attrs: {
                        disabled: _vm.checkedList.length < 2,
                        color: "success",
                        type: "border"
                      },
                      on: { click: _vm.mergeTopics }
                    },
                    [_vm._v("\n        Birleştir\n      ")]
                  )
                : _vm._e(),
              _vm.$store.state.route.query.search
                ? _c("div", [
                    _c("h3", [
                      _vm._v(
                        "aranan: " + _vm._s(_vm.$store.state.route.query.search)
                      )
                    ])
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "con-input-search vs-table--search" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.search,
                        expression: "search"
                      }
                    ],
                    staticClass: "input-search vs-table--search-input",
                    attrs: { maxlength: "50", type: "text" },
                    domProps: { value: _vm.search },
                    on: {
                      keyup: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleSearch($event)
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.search = $event.target.value
                      }
                    }
                  }),
                  _c("vs-icon", { attrs: { icon: "search" } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "thead" },
            [
              _vm.isMerging
                ? _c("vs-th", { attrs: { "sort-key": "check" } }, [_vm._v(" ")])
                : _vm._e(),
              _c("vs-th", { attrs: { "sort-key": "status" } }, [
                _vm._v("Durum")
              ]),
              _c("vs-th", { attrs: { "sort-key": "topic" } }, [
                _vm._v("Başlık")
              ]),
              _c("vs-th", { attrs: { "sort-key": "username" } }, [
                _vm._v("Kullanıcı")
              ]),
              false
                ? _c("vs-th", { attrs: { "sort-key": "onedioId" } }, [
                    _vm._v("Onedio ID")
                  ])
                : _vm._e(),
              _c(
                "vs-th",
                {
                  attrs: { "sort-key": "createDate" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.handleSort("createDate", "asc")
                    }
                  }
                },
                [
                  _vm._v("\n        Oluşturulma Tarihi\n        "),
                  _vm.$store.state.route.query.orderKey === "createDate" &&
                  _vm.$store.state.route.query.orderValue === "asc"
                    ? _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "expand_less" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.route.query.orderKey === "createDate" &&
                  _vm.$store.state.route.query.orderValue === "desc"
                    ? _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "expand_more" } })],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c(
                "vs-th",
                {
                  attrs: { "sort-key": "updateDate" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.handleSort("updateDate", "desc")
                    }
                  }
                },
                [
                  _vm._v("\n        Güncellenme Tarihi\n        "),
                  _vm.$store.state.route.query.orderKey === "updateDate" &&
                  _vm.$store.state.route.query.orderValue === "asc"
                    ? _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "expand_less" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.state.route.query.orderKey === "updateDate" &&
                  _vm.$store.state.route.query.orderValue === "desc"
                    ? _c(
                        "span",
                        [_c("vs-icon", { attrs: { icon: "expand_more" } })],
                        1
                      )
                    : _vm._e()
                ]
              ),
              false
                ? _c("vs-th", { attrs: { "sort-key": "publishDate" } }, [
                    _vm._v("Yayınlanma Tarihi")
                  ])
                : _vm._e(),
              _c("vs-th")
            ],
            1
          )
        ],
        2
      ),
      _c("popup-merge", {
        attrs: { list: _vm.listData, "is-active": _vm.showMergeDialog },
        on: {
          close: function($event) {
            _vm.showMergeDialog = false
          }
        }
      }),
      _c("popup-topic-entry", {
        attrs: { topic: _vm.selected, "is-active": _vm.showTopicEntryDialog },
        on: {
          close: function($event) {
            _vm.showTopicEntryDialog = false
          }
        }
      }),
      _c("popup-update-topic", {
        attrs: { "is-active": _vm.showUpdateDialog, topic: _vm.selected },
        on: {
          close: function($event) {
            _vm.showUpdateDialog = false
          }
        }
      }),
      _c("popup-add-instagram", {
        attrs: { topic: _vm.selected, "is-active": _vm.showInstagramDialog },
        on: {
          close: function($event) {
            _vm.showInstagramDialog = false
          }
        }
      }),
      _c("popup-send-message", {
        attrs: { user: _vm.selected, "is-active": _vm.showSendMessageDialog },
        on: {
          close: function($event) {
            _vm.showSendMessageDialog = false
          }
        }
      }),
      _c("popup-complaint-send-message", {
        attrs: {
          user: _vm.selected.user,
          "is-active": _vm.complaintMessageDialog,
          topic: _vm.topic,
          id: _vm.id,
          userId: _vm.userId,
          tab: _vm.tab
        },
        on: {
          close: function($event) {
            _vm.complaintMessageDialog = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }