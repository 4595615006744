var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-prompt",
    {
      attrs: {
        cancel: "close",
        "accept-text": "Güncelle",
        "cancel-text": "Vazgeç",
        active: _vm.active,
        title: "Başlığı Güncelle"
      },
      on: {
        accept: _vm.submit,
        close: _vm.close,
        "update:active": function($event) {
          _vm.active = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("vs-textarea", {
            attrs: { counter: "140", "counter-danger": _vm.counterDanger },
            on: {
              "update:counterDanger": function($event) {
                _vm.counterDanger = $event
              },
              "update:counter-danger": function($event) {
                _vm.counterDanger = $event
              }
            },
            model: {
              value: _vm.topicData.topic,
              callback: function($$v) {
                _vm.$set(_vm.topicData, "topic", $$v)
              },
              expression: "topicData.topic"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }