var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "vs-popup",
        {
          attrs: { title: "Instagram Köprü Ekle", active: _vm.active },
          on: {
            "update:active": function($event) {
              _vm.active = $event
            },
            close: _vm.close
          }
        },
        [
          _c("div", [
            _c("div", { staticClass: "mb-4" }, [
              _vm._v(
                "Instagram Köprü sayfasında görüntülenecek resmi yükleyin."
              )
            ]),
            _c("div", { staticClass: "upload__wrapper" }, [
              _c("div", { staticClass: "upload flex space-x-6" }, [
                _c("div", { staticClass: "btn btn--media" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "label label--file-input cursor-pointer flex w-16 h-16 rounded-md items-center justify-center m-0 text-white bg-red-600 hover:bg-red-500 transition-colors duration-200",
                      class: { "cursor-not-allowed": _vm.isimageAddDisabled }
                    },
                    [
                      _c(
                        "svg",
                        {
                          staticClass: "w-10 h-10",
                          attrs: { fill: "currentColor", viewBox: "0 0 20 20" }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              d:
                                "M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z",
                              "clip-rule": "evenodd"
                            }
                          })
                        ]
                      ),
                      _c("input", {
                        ref: "fileInput",
                        staticClass: "input input--file hidden",
                        attrs: {
                          disabled: _vm.isimageAddDisabled,
                          type: "file",
                          accept: "image/*",
                          multiple: "multiple"
                        },
                        on: { change: _vm.handleImageInput }
                      })
                    ]
                  )
                ]),
                _vm.images.length
                  ? _c("div", { staticClass: "images__wrapper" }, [
                      _c(
                        "div",
                        { staticClass: "images" },
                        _vm._l(_vm.images, function(img, $index) {
                          return _c(
                            "div",
                            {
                              key: img.id,
                              staticClass:
                                "media-wrapper flex items-center justify-center max-w-sm"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "media relative",
                                  class: { "media--bottom": $index > 4 }
                                },
                                [
                                  img.source
                                    ? [
                                        _c("img", {
                                          staticClass:
                                            "img img--media w-full h-full object-cover",
                                          attrs: { src: img.url }
                                        }),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn--remove cursor-pointer shadow-lg bg-white border-none absolute right-0 top-0 transform translate-x-1/2 text-black flex items-center justify-center w-8 h-8 rounded-full",
                                            attrs: { title: "Resmi Sil" },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeImage(img)
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  fill: "currentColor",
                                                  width: "20",
                                                  height: "20",
                                                  viewBox: "0 0 20 20"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "fill-rule": "evenodd",
                                                    d:
                                                      "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
                                                    "clip-rule": "evenodd"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          { staticClass: "w-16 h-16" },
                                          [
                                            _c("img", {
                                              staticClass: "w-16 h-16",
                                              attrs: {
                                                src: require("@/assets/img/loading.gif"),
                                                alt: "yükleniyor"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                ],
                                2
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "action-buttons text-right mt-2" },
                [
                  _c(
                    "vs-button",
                    { attrs: { type: "flat" }, on: { click: _vm.close } },
                    [_vm._v("\n          Vazgeç\n        ")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: {
                        disabled:
                          _vm.isSubmitDisabled || _vm.images.length !== 1
                      },
                      on: { click: _vm.submit }
                    },
                    [_vm._v("\n          Ekle\n        ")]
                  )
                ],
                1
              )
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }