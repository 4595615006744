<template>
  <vs-popup class="popup-merge" title="Birleştir" :active.sync="active" @close="close" v-if="active" buttonCloseHidden>
    <div class="modal-body ">
      <div class="space-y-2">
        <label class="text-sm opacity-75 ">Birleştirilecek başlığı seçiniz.</label>
        <v-select
          :options="checkedList"
          :reduce="(item) => item.id"
          label="topic"
          :clearable="false"
          v-model="selectedOption"
          class="mb-4 md:mb-0 select-topic"
        >
          <div slot="no-options">Sonuç bulunamadı</div>
        </v-select>
      </div>
      <div class="action-buttons text-right mt-8">
        <vs-button type="flat" @click="close">
          Vazgeç
        </vs-button>
        <vs-button class="ml-4" @click="submit">
          Birleştir
        </vs-button>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import vSelect from 'vue-select';
export default {
  components: {
    vSelect
  },
  props: ['isActive', 'list'],
  data() {
    return {
      options: [],
      active: false,
      selectedOption: ''
    };
  },
  computed: {
    ...mapGetters('topics', ['mergeList']),
    checkedList() {
      return this.list.filter((item) => item.checked);
    }
  },
  methods: {
    ...mapActions('topics', ['mergeTopics']),
    async submit() {
      const id = this.selectedOption; //birleştirilecek id
      const otherTopicIds = []; //birleştirilenlerin id'leri
      this.checkedList.forEach(function(item) {
        if (item.id !== id) {
          otherTopicIds.push(item.id);
        }
      });
      const body = { id, otherTopicIds };
      const payload = { body, filterList: this.checkedList.map((item) => item.id) };
      try {
        await this.mergeTopics(payload);
        this.$vs.notify({
          color: 'success',
          text: 'Başlıklar başarıyla birleştirildi.'
        });
      } catch (e) {
        this.$vs.notify({
          color: 'danger',
          text: 'Başlıklar birleştirilemedi.'
        });
      }
      this.$emit('close', false);
    },
    close() {
      this.$emit('close', false, false);
    }
  },
  watch: {
    isActive(val) {
      this.active = val;
    }
  }
};
</script>
<style lang="scss">
.select-topic {
  .vs__dropdown-option {
    padding: 10px;
    font-size: 12px;
  }
  .vs__selected-options {
    flex-wrap: nowrap;
  }
}
.popup-merge {
  .vs-popup--content {
    overflow: unset;
  }
}
</style>
