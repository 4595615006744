<template>
  <vs-popup title="Dio" :active.sync="active" @close="close" v-if="active">
    <div>
      <div class="dio__wrapper" v-if="topicData">
        <div id="dio-title" v-if="topicData.topic">
          <strong id="entrytopic">
            {{ topicData.topic.topic }}
          </strong>
        </div>
        <div id="entryhtml" class="mt-3 whitespace-pre-wrap " v-text="topicData.entry.entryData"></div>
        <div class="flex break-word my-2" v-if="topicData.entry.entryImageUrls && topicData.entry.entryImageUrls.length">
          <div v-for="(img, index) in topicData.entry.entryImageUrls" :key="index" class="w-1/5">
            <img :src="getImageUrl(img, '75x75')" class="rounded image-entry pl-0 p-2" />
          </div>
        </div>
      </div>
      <div v-else>Yükleniyor...</div>
    </div>
  </vs-popup>
</template>

<script>
import { generateImageUrl } from '@/utils/imageHash';
import TopicService from '@/services/TopicService';
export default {
  props: ['topic', 'isActive'],
  data() {
    return {
      active: false,
      topicData: null
    };
  },
  mounted() {},
  watch: {
    isActive(val) {
      this.active = val;
    },
    topic() {
      this.init();
    }
  },
  methods: {
    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        quality: '80',
        imageUrl: image,
        gifToImage: true
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },
    async init() {
      this.topicData = null;
      const result = await TopicService.getEntriesById({ id: this.topic.id, perPage: 1, page: 1 });
      this.topicData = result.data[0];
    },
    close() {
      this.$emit('close', false);
    }
  }
};
</script>

<style></style>
