<template>
  <div>
    <vs-button
      v-bind:class="{ active: $query.tab === 'all' }"
      color="primary"
      :type="$query.tab === 'all' ? 'filled' : 'line'"
      @click="handleTabClick('all')"
      class="tab-btn"
    >
      Tüm Başlıklar
      <span class="font-medium" v-if="total && $query.tab === 'all'">({{ total }})</span>
    </vs-button>
    <vs-button
      v-bind:class="{ active: $query.tab === 'approved' }"
      color="success"
      :type="$query.tab === 'approved' ? 'filled' : 'line'"
      @click="handleTabClick('approved')"
      class="tab-btn"
    >
      Onaylananlar
      <span class="font-medium" v-if="total && $query.tab === 'approved'">({{ total }})</span>
    </vs-button>
    <vs-button
      v-bind:class="{ active: $query.tab === 'waiting' }"
      color="warning"
      :type="$query.tab === 'waiting' ? 'filled' : 'line'"
      @click="handleTabClick('waiting')"
      class="tab-btn"
    >
      Bekleyenler
      <span class="font-medium" v-if="total && $query.tab === 'waiting'">({{ total }})</span>
    </vs-button>
    <vs-button
      v-bind:class="{ active: $query.tab === 'deleted' }"
      color="danger"
      :type="$query.tab === 'deleted' ? 'filled' : 'line'"
      @click="handleTabClick('deleted')"
      class="tab-btn"
    >
      Silinenler
      <span class="font-medium" v-if="total && $query.tab === 'deleted'">({{ total }})</span>
    </vs-button>
    <vs-button
      v-bind:class="{ active: $query.tab === 'pinned' }"
      color="#333"
      :type="$query.tab === 'pinned' ? 'filled' : 'line'"
      @click="handleTabClick('pinned')"
      class="tab-btn"
    >
      Sabitler
      <span class="font-medium" v-if="total && $query.tab === 'pinned'">({{ total }})</span>
    </vs-button>
    <vs-button
      v-bind:class="{ active: $query.tab === 'picked' }"
      color="#333"
      :type="$query.tab === 'picked' ? 'filled' : 'line'"
      @click="handleTabClick('picked')"
      class="tab-btn"
    >
      Seçmece
      <span class="font-medium" v-if="total && $query.tab === 'picked'">({{ total }})</span>
    </vs-button>
    <vs-button
      v-bind:class="{ active: $query.tab === 'merge' }"
      color="dark"
      :type="$query.tab === 'merge' ? 'filled' : 'line'"
      @click="handleMergeClick"
      class="tab-btn"
    >
      Birleştirilecekler
      <span class="font-medium" v-if="mergeList.length">({{ mergeList.length }})</span>
    </vs-button>
    <topic-list
      :list="list"
      :filterType="filterType"
      @search="handleSearch"
      @action="handleActions"
      :mergeList="mergeList"
      :isMerging="isMerging"
      @updateMergeList="handleUpdateMergeList"
    ></topic-list>
    <div v-if="!isMerging" class="con-pagination-table vs-table--pagination">
      <vs-pagination :total="getTotalPages" v-model="page"></vs-pagination>
    </div>
  </div>
</template>

<script>
import TopicList from '@/components/topic/TopicList.vue';
import { usePagination, useQueryParams, usePageRefresh } from '@/mixins';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  components: { TopicList },
  mixins: [usePagination({ moduleName: 'topics' }), useQueryParams, usePageRefresh],
  computed: {
    ...mapGetters('topics', ['list', 'mergeList', 'isMerging', 'currentState', 'total', 'filterType'])
  },
  data: () => ({ defaultTab: 'approved' }),
  methods: {
    ...mapMutations('topics', ['setTopicListType', 'setIsMerging', 'setMergeList', 'setSearchText']),
    ...mapActions('topics', [
      'getTopics',
      'setListFromMerge',
      'approveTopic',
      'deleteTopic',
      'restoreTopic',
      'setListFromMerge',
      'pinTopic',
      'pickTopic',
      'upPickedTopic',
      'changeThumbnail',
      'lockTopic'
    ]),
    ...mapMutations('entry', { entrySetList: 'setList' }),
    resetQueryParams() {
      delete this.$query.search;
      delete this.$query.tab;
      delete this.$query.id;
      delete this.$query.orderKey;
      delete this.$query.orderValue;
    },
    setDefaultQueryParams() {
      if (this.$query.hasOwnProperty('search')) return;
      if (this.$query.hasOwnProperty('id')) return;
      if (this.$query.hasOwnProperty('orderKey')) return;
      if (this.$query.hasOwnProperty('orderValue')) return;
      if (!this.$query.hasOwnProperty('tab') || !this.$query.hasOwnProperty('page') || !this.$query.hasOwnProperty('perPage')) {
        const queryParams = { ...this.getPaginationParams, ...this.$query };
        this.$router.replace({ query: { ...queryParams, tab: this.defaultTab } });
      }
    },
    changeRoute(query, { reset, replace }) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      const method = replace ? 'replace' : 'push';
      this.$router[method](newPath);
    },
    filterList(type) {
      if (type === 'merge') {
        this.setIsMerging(true);
        this.setListFromMerge();
      } else {
        this.$vs.loading();
        this.getTopics(type).finally(() => {
          this.$vs.loading.close();
        });
      }
    },

    handleAction({ text, successText, action, payload }) {
      if (confirm(text)) {
        this.$vs.loading();
        this[action](payload)
          .then(() => {
            this.$vs.notify({
              color: 'success',
              text: successText
            });
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      }
    },

    handleApproveTopic(action, { id }) {
      const text = 'Başlığı onaylamak istediğinize emin misiniz?';
      const successText = 'Başlık Onaylandi.';
      this.handleAction({ text, successText, payload: id, action });
    },

    handleDeleteTopic(action, { id }) {
      const text = 'Başlığı silmek istediğinize emin misiniz?';
      const successText = 'Başlık Silindi.';
      this.handleAction({ text, successText, payload: id, action });
    },

    handleRestoreTopic(action, { id }) {
      const text = 'Başlığı geri getirmek istediğinize emin misiniz?';
      const successText = 'Başlık Geri Getirildi.';
      this.handleAction({ text, successText, payload: id, action });
    },

    handlePinTopic(action, { id, pin }) {
      let text = 'Başlığı sabitlemek istediğinize emin misiniz?';
      let successText = 'Başlık Sabitlendi';
      if (!pin) {
        text = 'Başlığı sabitlenenlerden kaldırmak istediğinize emin misiniz?';
        successText = 'Başlık sabitlenenlerden kaldırıldı.';
      }
      const payload = { id, pin };
      this.handleAction({ text, successText, payload, action });
    },

    handlePickTopic(action, { id, pick }) {
      let text = `Başlığı Seçmece'ye eklemek istedığınızden emin misiniz?`;
      let successText = `Başlık Seçmece'ye eklendi.`;
      if (!pick) {
        text = `Başlığı Seçmece'den kaldırmak istediğinize emin misiniz?`;
        successText = `Başlık Seçmece'den kaldırıldı.`;
      }
      const payload = { id, pick };
      this.handleAction({ text, successText, payload, action });
    },

    handleLockTopic(action, { id, lock }) {
      let text = `Başlığı kilitlemek istedığınızden emin misiniz?`;
      let successText = `Başlık kilitlendi.`;
      if (!lock) {
        text = `Başlık kilidini kaldırmak istediğinize emin misiniz?`;
        successText = `Başlık kilidi kaldırıldı.`;
      }
      const payload = { id, lock };
      this.handleAction({ text, successText, payload, action });
    },

    handleUpPickedTopic(action, { id }) {
      let text = `Başlığı yukarı çıkarmak istedığınızden emin misiniz?`;
      let successText = `Başlık yukarı çıkarıldı.`;
      const payload = { id };
      this.handleAction({ text, successText, payload, action });
    },

    handleChangeThumbnail(action, { id, image }) {
      let text = `Başlığın thumbnail'ini değiştirmek istediğinizden emin misiniz?`;
      let successText = `thumbnail değiştirildi.`;
      const payload = { id, image };

      this.handleAction({ text, successText, payload, action });
    },

    handleUpdateMergeList(list) {
      this.setMergeList(list);
    },

    handleMergeAddTopic(action, { topic }) {
      const currentMergeList = [...this.mergeList];
      currentMergeList.push(topic);
      this.setMergeList(currentMergeList);
    },

    handleMergeRemoveTopic(action, { topic }) {
      let currentMergeList = [...this.mergeList];
      currentMergeList = currentMergeList.filter((current) => current.id !== topic.id);
      this.setMergeList(currentMergeList);
      if (this.isMerging) {
        this.setListFromMerge();
      }
    },

    handleMergeClick() {
      this.changeRoute({ tab: 'merge', page: 1 }, { reset: true });
    },

    handleTabClick(type) {
      this.changeRoute({ tab: type, page: 1 }, { reset: true });
    },

    handleGotoDio(action, { id }) {
      event.stopPropagation();
      this.entrySetList([]);
      this.resetQueryParams();
      this.$router.push({ name: 'entry', query: { ...this.$query, topicId: id } });
    },

    handleActions({ action, payload }) {
      const handleName = 'handle' + _.upperFirst(action);
      this[handleName](action, payload);
    },

    handleSearch({ text }) {
      const oldVal = this.$query.search;
      if (text === oldVal) {
        return;
      }
      if (text.length > 0 && text.length < 3) {
        this.$vs.notify({
          text: 'Minumum 3 karakter girmelisiniz.',
          color: 'warning'
        });
      }
      if (text.length === 0) {
        this.changeRoute({ page: 1, tab: this.defaultTab }, { reset: true });
      }
      if (text.length >= 3) {
        this.changeRoute({ search: text }, { reset: true });
      }
    },
    async fetchData() {
      this.setDefaultQueryParams();
      this.setPagination(this.$query);
      await this.filterList(this.$query.tab);
    }
  },
  async created() {
    this.fetchData();
  }
};
</script>

<style>
.tab-btn + .tab-btn {
  margin: 5px;
}
</style>
