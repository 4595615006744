<template>
  <vs-popup title="Instagram Köprü Ekle" :active.sync="active" @close="close" v-if="active">
    <div>
      <div class="mb-4">Instagram Köprü sayfasında görüntülenecek resmi yükleyin.</div>
      <div class="upload__wrapper">
        <div class="upload flex space-x-6">
          <div class="btn btn--media">
            <!-- eslint-disable -->
            <label class="label label--file-input cursor-pointer flex w-16 h-16 rounded-md items-center justify-center m-0 text-white bg-red-600 hover:bg-red-500 transition-colors duration-200" :class="{'cursor-not-allowed': isimageAddDisabled}">
              <svg fill="currentColor" class="w-10 h-10" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clip-rule="evenodd"></path></svg>
              <input ref="fileInput" :disabled="isimageAddDisabled" @change="handleImageInput" class="input input--file hidden" type="file" accept="image/*" multiple="multiple" />
            </label>
            <!-- eslint-enable -->
          </div>
          <div v-if="images.length" class="images__wrapper">
            <div class="images">
              <div v-for="(img, $index) in images" :key="img.id" class="media-wrapper flex items-center justify-center max-w-sm">
                <div :class="{ 'media--bottom': $index > 4 }" class="media relative">
                  <template v-if="img.source">
                    <img :src="img.url" class="img img--media w-full h-full object-cover" />
                    <button
                      @click="removeImage(img)"
                      title="Resmi Sil"
                      class="btn btn--remove cursor-pointer shadow-lg bg-white border-none absolute right-0 top-0 transform translate-x-1/2 text-black flex items-center justify-center w-8 h-8 rounded-full"
                    >
                      <!-- eslint-disable-next-line prettier/prettier -->
                      <svg fill="currentColor" width="20" height="20" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"></path></svg>
                    </button>
                  </template>
                  <template v-else>
                    <div class="w-16 h-16">
                      <img class="w-16 h-16" src="@/assets/img/loading.gif" alt="yükleniyor" />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="reply-buttons">
          <button v-if="$listeners.cancel" @click="cancel" class="btn btn--cancel">Vazgeç</button>
          <button @click="submit" :disabled="isSubmitDisabled" class="btn btn--send">Kaydet</button>
        </div> -->
        <div class="action-buttons text-right mt-2">
          <vs-button type="flat" @click="close">
            Vazgeç
          </vs-button>
          <vs-button class="ml-4" :disabled="isSubmitDisabled || images.length !== 1" @click="submit">
            Ekle
          </vs-button>
        </div>
      </div>
    </div>
  </vs-popup>
</template>

<script>
import { mapActions } from 'vuex';
import { uniqueId, cloneDeep } from 'lodash-es';
import { generateImageUrl } from '@/utils/imageHash';
import UploadService from '@/services/UploadService';
export default {
  name: 'EntryUpdate',
  props: ['isActive', 'topic'],
  data() {
    return {
      active: false,
      topicData: {},
      imagesUploading: false,
      images: [],
      maxFileCount: 1
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.imagesUploading;
    },
    isimageAddDisabled() {
      return this.images.length === this.maxFileCount;
    }
  },
  methods: {
    ...mapActions('instagram', ['addInstagramBridge']),
    init() {
      this.images = [];
      this.imagesUploading = false;
    },
    close() {
      this.$emit('close', false);
    },

    addImage({ url, source, ...rest }) {
      this.images.push({ id: uniqueId(), url, source, ...rest });
    },

    removeImage(image) {
      this.images = this.images.filter((item) => item.id !== image.id);
      this.clearFileInput(); // input'u temizlemezsek en son sildigimiz resmi tekrar yukleyemeyiz cunku hala o secili inputta.
    },

    clearFileInput() {
      this.$refs.fileInput.value = '';
    },

    addImages() {
      const files = this.$refs.fileInput.files;
      for (const image of files) {
        if (this.checkImageExists(image)) continue; // break degil cunku belki 8 fotograf ekledi 2.fotograf zaten varsa diger 6 fotografi eklemeye devam etsin
        if (!this.checkImage(image)) return; // bu da mi continue olmali?
        // hersey yolundaysa
        this.addImage({
          url: typeof file === 'string' ? image : URL.createObjectURL(image),
          source: null,
          image
        });
      }
    },

    handleImageInput() {
      this.addImages(); // resmi dom'a ekle
      this.uploadImages(); // resim yuklendikten sonra upload et
    },

    async uploadImages() {
      this.imagesUploading = true;
      // promıse.all ıle resimler paralel yüklenir yani aynı anda 5 resim gibi.
      // tek tek yüklemek için Promise.All yerine:
      // for (const item of this.images) {
      await Promise.all(
        this.images.map(async (item) => {
          if (item.source) return;
          const formData = new FormData();
          formData.append('image', item.image);
          const upload = await UploadService.upload(formData);
          item.source = upload.urlEncodedFileName;
        })
      );
      this.imagesUploading = false;
      this.$forceUpdate();
    },

    checkImageExists(image) {
      return this.images.find((file) => file.image.name === image.name);
    },

    getImageUrl(image, size = '75x75') {
      const type = image.split('.')[1];
      const params = {
        size,
        type,
        quality: '80',
        imageUrl: image
      };
      const newUrl = generateImageUrl(params);
      return newUrl;
    },

    clear() {
      this.text = '';
      this.images = [];
    },

    cancel() {
      this.$emit('cancel');
    },

    // validation
    checkImage(image) {
      const MAX_IMG_FILE_SIZE = 52428800;
      const MAX_GIF_FILE_SIZE = 4194304;
      const MAX_FILES_COUNT = this.maxFileCount;
      const imageTypes = ['png', 'gif', 'jpeg', 'jpg'];
      const isImage = image.type && image.type.includes('/') && !imageTypes.includes(image.type.split('/')[1]);

      if (this.images.length >= MAX_FILES_COUNT) {
        this.$swal({
          text: `En fazla ${MAX_FILES_COUNT} adet fotoğraf yükleyebilirsiniz.`,
          closeButtonHtml: '<div class="error"></div>'
        });
        return false;
      }

      if (isImage) {
        this.$toast.error(`<i></i><span>Bu format desteklenmiyor. jpg,gif,png dene istersen.</span>`, {
          className: 'dioToasted',
          duration: '5000'
        });
        return false;
      }

      if (image.type === 'image/gif') {
        if (image.size > MAX_GIF_FILE_SIZE) {
          this.$toast.error(`<i></i><span>Gifler için dosya 4 MB'dan büyük olamaz.</span>`, {
            className: 'dioToasted',
            duration: '5000'
          });
          return false;
        }
      }

      if (image.size > MAX_IMG_FILE_SIZE) {
        this.$toast.error(`<i></i><span>Dosya 50 MB'dan büyük olamaz.</span>`, {
          className: 'dioToasted',
          duration: '5000'
        });
        return false;
      }

      return true;
    },
    async submit() {
      if (confirm('Instagram Köprü için eklemek istediğinizden emin misiniz?')) {
        try {
          const thumbnail = this.images[0];

          let payload = {
            thumbnail: thumbnail.source,
            id: this.topicData.id
          };
          await this.addInstagramBridge(payload);
          this.$vs.notify({
            color: 'success',
            text: 'Başarıyla eklendi.'
          });
          this.$emit('close', false);
        } catch (e) {
          this.$vs.notify({
            color: 'danger',
            text: 'İşlem başarısız. Lütfen tekrar deneyin.'
          });
        }
      }
    }
  },
  watch: {
    isActive(val) {
      this.active = val;
    },
    topic(val) {
      this.topicData = cloneDeep(val);
      this.init(); // popup zaten mounted oldugu icin
    }
  }
};
</script>
<style>
.img--media {
  max-width: 340px;
  max-height: 170px;
}
.vs-tooltip {
  z-index: 55000;
}
</style>
