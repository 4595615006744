<template>
  <vs-prompt
    cancel="close"
    @accept="submit"
    accept-text="Güncelle"
    cancel-text="Vazgeç"
    @close="close"
    :active.sync="active"
    title="Başlığı Güncelle"
  >
    <div>
      <vs-textarea counter="140" :counter-danger.sync="counterDanger" v-model="topicData.topic" />
    </div>
  </vs-prompt>
</template>

<script>
import { mapActions } from 'vuex';
import * as _ from 'lodash-es';
export default {
  name: 'TopicUpdate',
  props: ['isActive', 'topic'],
  data() {
    return {
      active: false,
      topicData: {},
      counterDanger: false
    };
  },
  computed: {},
  methods: {
    ...mapActions('topics', ['updateTopic', 'approveTopic']),
    close() {
      this.$emit('close', false);
    },
    async submit() {
      if (confirm('Başlığı güncellemek istediğinize emin misiniz?')) {
        try {
          let json = {
            id: this.topicData.id,
            topic: this.topicData.topic
          };
          await this.updateTopic(json);
          this.$vs.notify({
            color: 'success',
            text: 'Başarıyla güncellendi.'
          });
          this.$emit('close', false);
        } catch (e) {
          this.$vs.notify({
            color: 'danger',
            text: 'Başlık güncellenemedi.'
          });
        }
      }
    }
  },
  watch: {
    isActive(val) {
      this.active = val;
    },
    topic(val) {
      this.topicData = _.cloneDeep(val);
    }
  }
};
</script>
