var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "all" },
          attrs: {
            color: "primary",
            type: _vm.$query.tab === "all" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("all")
            }
          }
        },
        [
          _vm._v("\n    Tüm Başlıklar\n    "),
          _vm.total && _vm.$query.tab === "all"
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.total) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "approved" },
          attrs: {
            color: "success",
            type: _vm.$query.tab === "approved" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("approved")
            }
          }
        },
        [
          _vm._v("\n    Onaylananlar\n    "),
          _vm.total && _vm.$query.tab === "approved"
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.total) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "waiting" },
          attrs: {
            color: "warning",
            type: _vm.$query.tab === "waiting" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("waiting")
            }
          }
        },
        [
          _vm._v("\n    Bekleyenler\n    "),
          _vm.total && _vm.$query.tab === "waiting"
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.total) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "deleted" },
          attrs: {
            color: "danger",
            type: _vm.$query.tab === "deleted" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("deleted")
            }
          }
        },
        [
          _vm._v("\n    Silinenler\n    "),
          _vm.total && _vm.$query.tab === "deleted"
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.total) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "pinned" },
          attrs: {
            color: "#333",
            type: _vm.$query.tab === "pinned" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("pinned")
            }
          }
        },
        [
          _vm._v("\n    Sabitler\n    "),
          _vm.total && _vm.$query.tab === "pinned"
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.total) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "picked" },
          attrs: {
            color: "#333",
            type: _vm.$query.tab === "picked" ? "filled" : "line"
          },
          on: {
            click: function($event) {
              return _vm.handleTabClick("picked")
            }
          }
        },
        [
          _vm._v("\n    Seçmece\n    "),
          _vm.total && _vm.$query.tab === "picked"
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.total) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c(
        "vs-button",
        {
          staticClass: "tab-btn",
          class: { active: _vm.$query.tab === "merge" },
          attrs: {
            color: "dark",
            type: _vm.$query.tab === "merge" ? "filled" : "line"
          },
          on: { click: _vm.handleMergeClick }
        },
        [
          _vm._v("\n    Birleştirilecekler\n    "),
          _vm.mergeList.length
            ? _c("span", { staticClass: "font-medium" }, [
                _vm._v("(" + _vm._s(_vm.mergeList.length) + ")")
              ])
            : _vm._e()
        ]
      ),
      _c("topic-list", {
        attrs: {
          list: _vm.list,
          filterType: _vm.filterType,
          mergeList: _vm.mergeList,
          isMerging: _vm.isMerging
        },
        on: {
          search: _vm.handleSearch,
          action: _vm.handleActions,
          updateMergeList: _vm.handleUpdateMergeList
        }
      }),
      !_vm.isMerging
        ? _c(
            "div",
            { staticClass: "con-pagination-table vs-table--pagination" },
            [
              _c("vs-pagination", {
                attrs: { total: _vm.getTotalPages },
                model: {
                  value: _vm.page,
                  callback: function($$v) {
                    _vm.page = $$v
                  },
                  expression: "page"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }