var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.active
    ? _c(
        "vs-popup",
        {
          attrs: { title: "Dio", active: _vm.active },
          on: {
            "update:active": function($event) {
              _vm.active = $event
            },
            close: _vm.close
          }
        },
        [
          _c("div", [
            _vm.topicData
              ? _c("div", { staticClass: "dio__wrapper" }, [
                  _vm.topicData.topic
                    ? _c("div", { attrs: { id: "dio-title" } }, [
                        _c("strong", { attrs: { id: "entrytopic" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.topicData.topic.topic) +
                              "\n        "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("div", {
                    staticClass: "mt-3 whitespace-pre-wrap ",
                    attrs: { id: "entryhtml" },
                    domProps: {
                      textContent: _vm._s(_vm.topicData.entry.entryData)
                    }
                  }),
                  _vm.topicData.entry.entryImageUrls &&
                  _vm.topicData.entry.entryImageUrls.length
                    ? _c(
                        "div",
                        { staticClass: "flex break-word my-2" },
                        _vm._l(_vm.topicData.entry.entryImageUrls, function(
                          img,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "w-1/5" },
                            [
                              _c("img", {
                                staticClass: "rounded image-entry pl-0 p-2",
                                attrs: { src: _vm.getImageUrl(img, "75x75") }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _c("div", [_vm._v("Yükleniyor...")])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }