<template>
  <div>
    <vs-table :multiple="false" stripe v-model="selected" :sst="true" :max-items="50" :data="listData" noDataText="">
      <div class="flex items-center justify-between w-full" slot="header">
        <vs-button
          @click="mergeTopics"
          :disabled="checkedList.length < 2"
          color="success"
          type="border"
          v-if="$store.state.route.query.tab === 'merge'"
        >
          Birleştir
        </vs-button>
        <div v-if="$store.state.route.query.search">
          <h3>aranan: {{ $store.state.route.query.search }}</h3>
        </div>
        <div class="con-input-search vs-table--search">
          <input v-model="search" maxlength="50" v-on:keyup.enter="handleSearch" class="input-search vs-table--search-input" type="text" />
          <vs-icon icon="search"></vs-icon>
        </div>
      </div>
      <template slot="thead">
        <vs-th sort-key="check" v-if="isMerging">&nbsp;</vs-th>
        <vs-th sort-key="status">Durum</vs-th>
        <vs-th sort-key="topic">Başlık</vs-th>
        <vs-th sort-key="username">Kullanıcı</vs-th>
        <vs-th sort-key="onedioId" v-if="false">Onedio ID</vs-th>
        <vs-th sort-key="createDate" @click.native="handleSort('createDate', 'asc')">
          Oluşturulma Tarihi
          <span v-if="$store.state.route.query.orderKey === 'createDate' && $store.state.route.query.orderValue === 'asc'">
            <vs-icon icon="expand_less"></vs-icon>
          </span>
          <span v-if="$store.state.route.query.orderKey === 'createDate' && $store.state.route.query.orderValue === 'desc'">
            <vs-icon icon="expand_more"></vs-icon>
          </span>
        </vs-th>
        <vs-th sort-key="updateDate" @click.native="handleSort('updateDate', 'desc')">
          Güncellenme Tarihi
          <span v-if="$store.state.route.query.orderKey === 'updateDate' && $store.state.route.query.orderValue === 'asc'">
            <vs-icon icon="expand_less"></vs-icon>
          </span>
          <span v-if="$store.state.route.query.orderKey === 'updateDate' && $store.state.route.query.orderValue === 'desc'">
            <vs-icon icon="expand_more"></vs-icon>
          </span>
        </vs-th>
        <vs-th sort-key="publishDate" v-if="false">Yayınlanma Tarihi</vs-th>
        <vs-th></vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="check" v-if="isMerging">
              <input type="checkbox" @change="handleCheckbox" v-model="tr.checked" name="test" id="" />
            </vs-td>
            <vs-td id="status">
              <div class="flex flex-wrap w-16">
                <div class="col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center">
                  <vx-tooltip :text="states[tr.state].text" class="state-tooltip">
                    <div
                      class="w-5 h-5 text-sm rounded-full text-white font-bold flex items-center justify-center leading-none"
                      :class="states[tr.state].color"
                    >
                      {{ states[tr.state].shortText }}
                    </div>
                  </vx-tooltip>
                </div>
                <div v-if="tr.locked" class="col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center">
                  <vx-tooltip text="Kilitli" class="state-tooltip">
                    <vs-icon icon="lock" size="small" />
                  </vx-tooltip>
                </div>
                <div v-if="tr.picked" class="col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center">
                  <vx-tooltip text="Seçmece" class="state-tooltip">
                    <vs-icon icon="bookmark" size="small" />
                  </vx-tooltip>
                </div>
                <div v-if="tr.pinned" class="col w-1/2 bg-gray-400x text-gray-500 h-8 flex items-center justify-center">
                  <vx-tooltip text="Sabit" class="state-tooltip">
                    <vs-icon icon="flag" size="small" />
                  </vx-tooltip>
                </div>
              </div>
            </vs-td>

            <vs-td id="topic" :data="tr.topic">
              <div class="flex items-center">
                <div class="topic__image-wrapper flex-shrink-0 img-loader flex mr-2 relative" :id="'img-' + tr.id">
                  <div class="topic__image w-16 h-16 bg-gray-300 rounded overflow-hidden flex items-center justify-center">
                    <img v-if="tr.thumbnailUrl" :src="getImageUrl(tr.thumbnailUrl, '75x75')" class="rounded p-0 w-16 h-16 object-cover" />
                    <!-- <img v-else :src="getImageUrl(tr.thumbnailUrl, '75x75')" class="rounded image-entry pl-0 p-2" /> -->
                    <vs-icon v-else size="large" class="text-gray-600" icon="insert_photo"></vs-icon>
                  </div>
                  <label
                    class="topic__image-hover absolute bg-gray-300 flex items-center justify-center w-full h-full rounded cursor-pointer"
                  >
                    <vs-icon size="large" class="text-gray-900" icon="insert_photo"></vs-icon>
                    <input class="hidden" type="file" @change="handleImageAdd($event, tr.id)" accept="image/*" />
                  </label>
                </div>
                <div @click="showTopicEntry" class="topic__text break-word font-medium text-black overflow-auto max-w-sm min-w-64">
                  {{ tr.topic }}
                </div>
              </div>
            </vs-td>

            <vs-td id="username" class="max-w-64 min-w-32 break-word">
              {{ tr.user && tr.user.username }}
            </vs-td>

            <vs-td id="onedioId" class="w-24" v-if="false">
              <p class="font-medium">{{ tr.onedioId }}</p>
            </vs-td>

            <vs-td id="createDate">
              <p class="font-medium w-24">
                {{ tr.createDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.createDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="updateDate">
              <p class="font-medium w-24">
                {{ tr.updateDate | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.updateDate | moment('HH:mm') }}
              </p>
            </vs-td>

            <vs-td id="publishDate" v-if="false">
              <p class="font-medium w-24">
                <template v-if="tr.publishDate">
                  {{ tr.publishDate | moment('DD-MM-YYYY') }}
                  <br />
                  {{ tr.publishDate | moment('HH:mm') }}
                </template>
                <template v-else>
                  -
                </template>
              </p>
            </vs-td>

            <vs-td id="action" :key="tr.id">
              <div class="flex items-center space-x-2 justify-end">
                <div class="flex space-x-1">
                  <vx-tooltip text="Diolari Gör" v-if="!isMerging">
                    <vs-button
                      @click="gotoDio($event, tr.id)"
                      color="primary"
                      size="small"
                      class="btn btn--action"
                      type="gradient"
                      icon="view_list"
                    ></vs-button>
                  </vx-tooltip>

                  <vx-tooltip text="Düzenle" v-if="!isMerging">
                    <vs-button
                      @click="editTopic"
                      color="secondary"
                      size="small"
                      class="btn btn--action"
                      type="gradient"
                      icon="edit"
                    ></vs-button>
                  </vx-tooltip>

                  <vx-tooltip text="Onayla" v-if="!isMerging && tr.state.includes('waiting')">
                    <vs-button
                      @click="handleApproveTopic($event, tr.id)"
                      color="success"
                      size="small"
                      class="btn btn--action"
                      type="gradient"
                      icon="check_circle"
                    ></vs-button>
                  </vx-tooltip>
                </div>

                <vs-dropdown
                  vs-trigger-click
                  class="action-button action-button--topic cursor-pointer rounded border border-solid border-gray-300 bg-gray-100 hover:bg-gray-300 px-1"
                >
                  <div class="flex items-center">
                    <feather-icon icon="MoreHorizontalIcon" svgClasses="more-icon" />
                  </div>

                  <vs-dropdown-menu class="w-56">
                    <vs-dropdown-item v-if="!isMerging && filterType === 'picked'" @click="handleUpPickedTopic($event, tr.id)">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon icon="arrow_upward" size="small" />
                        </div>
                        <span>Yukarı Çıkar</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item v-if="!isMerging" @click="handleLockTopic($event, tr.id, tr.locked)">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon :icon="tr.locked ? 'lock' : 'lock_open'" size="small" />
                        </div>
                        <span>{{ tr.locked ? `Kilidi Kaldır` : `Kilitle` }}</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item v-if="!isMerging" @click="handlePickTopic($event, tr.id, tr.picked)">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon :icon="tr.picked ? 'bookmark' : 'bookmark_border'" size="small" />
                        </div>
                        <span>{{ tr.picked ? `Seçmece'den Çıkar` : `Seçmece'ye Ekle` }}</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item v-if="!isMerging" @click="handlePinTopic($event, tr.id, tr.pinned)">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon :icon="tr.pinned ? 'flag' : 'outlined_flag'" size="small" />
                        </div>
                        <span>{{ tr.pinned ? 'Sabitten Çıkar' : 'Sabitle' }}</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item @click="showInstagramDialog = true" v-if="!isMerging && !tr.state.includes('deleted')">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon icon="icon-instagram" icon-pack="feather" />
                        </div>
                        <span>Instagram'a Ekle</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item @click="showSendMessageDialog = true" v-if="!isMerging && !$store.state.route.query.search">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon icon="icon-send" icon-pack="feather" />
                        </div>
                        <span>Mesaj Gönder</span>
                      </span>
                    </vs-dropdown-item>

                    <!-- eslint-disable-next-line -->
                    <vs-dropdown-item @click="handleMergeTopic($event, tr, mergeList.find((item) => item.id === tr.id))">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon :icon="mergeList.find((item) => item.id === tr.id) ? 'undo' : 'playlist_add'" size="small" />
                        </div>
                        <span>{{ mergeList.find((item) => item.id === tr.id) ? "Birleştir'den Çıkar" : "Birleştir'e Ekle" }}</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item
                      @click="(complaintMessageDialog = true), deleteEntry($event, tr.topic, tr.id, tr.userId)"
                      v-if="!isMerging && !tr.state.includes('deleted')"
                    >
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon icon="icon-trash-2" icon-pack="feather" />
                        </div>
                        <span>Sil</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item @click="handleRestoreTopic($event, tr.id)" v-if="!isMerging && tr.state.includes('deleted')">
                      <span class="flex items-center text-base">
                        <div class="-ml-3 flex items-center justify-center w-10">
                          <vs-icon icon="settings_backup_restore" size="small" />
                        </div>
                        <span>Geri Getir</span>
                      </span>
                    </vs-dropdown-item>
                  </vs-dropdown-menu>
                </vs-dropdown>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <popup-merge :list="listData" :is-active="showMergeDialog" @close="showMergeDialog = false" />
    <popup-topic-entry :topic="selected" :is-active="showTopicEntryDialog" @close="showTopicEntryDialog = false" />
    <popup-update-topic :is-active="showUpdateDialog" @close="showUpdateDialog = false" :topic="selected" />
    <popup-add-instagram :topic="selected" :is-active="showInstagramDialog" @close="showInstagramDialog = false" />
    <popup-send-message :user="selected" :is-active="showSendMessageDialog" @close="showSendMessageDialog = false" />
    <popup-complaint-send-message
      :user="selected.user"
      :is-active="complaintMessageDialog"
      @close="complaintMessageDialog = false"
      :topic="topic"
      :id="id"
      :userId="userId"
      :tab="tab"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import PopupTopicEntry from '@/components/popups/PopupTopicEntry.vue';
import PopupUpdateTopic from '@/components/topic/PopupUpdateTopic.vue';
import PopupMerge from '@/components/topic/PopupMerge.vue';
import PopupAddInstagram from '@/components/instagram/PopupAddInstagram.vue';
import PopupSendMessage from '@/components/popups/PopupSendMessage.vue';
import { generateImageUrl } from '@/utils/imageHash';
import { useQueryParams } from '@/mixins';
import PopupComplaintSendMessage from '@/components/popups/PopupComplaintSendMessage';
import { mapActions } from 'vuex';
export default {
  props: ['list', 'mergeList', 'isMerging', 'filterType'],
  mixins: [useQueryParams],
  components: { PopupMerge, PopupUpdateTopic, PopupTopicEntry, PopupAddInstagram, PopupSendMessage, PopupComplaintSendMessage },
  computed: {
    checkedList() {
      return this.listData.filter((item) => item.checked);
    }
  },
  data: () => ({
    search: '',
    listData: [],
    selected: [],
    showTopicEntryDialog: false,
    showMergeDialog: false,
    showUpdateDialog: false,
    showInstagramDialog: false,
    showSendMessageDialog: false,
    topic: {},
    id: '',
    complaintMessageDialog: false,
    userId: '',
    tab: 'topic',
    states: {
      deleted: {
        color: 'bg-red-500',
        shortText: 'S',
        text: 'Silinmiş'
      },
      waiting: {
        color: 'bg-orange-500',
        shortText: 'B',
        text: 'Bekleyen'
      },
      approved: {
        color: 'bg-green-500',
        shortText: 'O',
        text: 'Onaylanmış'
      },
      restored: {
        color: 'bg-gray-500',
        shortText: 'GG',
        text: 'Geri Getirilmiş'
      }
    }
  }),
  created() {
    this.search = this.$query.search || '';
  },
  watch: {
    search(val) {
      const query = this.$query;
      if (val.length === 0 && query.hasOwnProperty('search')) {
        delete query.search;
        this.$router.replace({ query: { ...query, page: 1, tab: 'approved' } });
      }
    },
    list: {
      immediate: true,
      handler(val) {
        this.listData = _.cloneDeep(val);
      }
    }
  },
  methods: {
    ...mapActions('complaint', { getList: 'getFilterList' }),
    handleCheckbox() {
      this.$emit('updateMergeList', _.cloneDeep(this.listData));
    },
    resetQueryParams() {
      const query = this.$query;
      delete query.search;
      delete query.tab;
    },
    editTopic() {
      this.showUpdateDialog = true;
    },
    handleApproveTopic($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'approveTopic', payload });
    },
    async deleteEntry($event, topic, id, userId) {
      this.topic = topic;
      this.id = id;
      this.userId = userId;
      $event.stopPropagation();
    },
    handleRestoreTopic($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'restoreTopic', payload });
    },

    handlePinTopic($event, id, pin) {
      $event.stopPropagation();
      const payload = { id, pin: !pin };
      this.$emit('action', { action: 'pinTopic', payload });
    },

    handlePickTopic($event, id, pick) {
      $event.stopPropagation();
      const payload = { id, pick: !pick };
      this.$emit('action', { action: 'pickTopic', payload });
    },

    handleLockTopic($event, id, lock) {
      $event.stopPropagation();
      const payload = { id, lock: !lock };
      this.$emit('action', { action: 'lockTopic', payload });
    },

    handleUpPickedTopic($event, id) {
      $event.stopPropagation();
      const payload = { id };
      this.$emit('action', { action: 'upPickedTopic', payload });
    },

    async mergeTopics($event) {
      $event.stopPropagation();
      this.showMergeDialog = true;
    },

    /**
     * @param {string} state merge listesinde var mı
     *
     * mergeListte varsa sil yoksa ekle
     */
    handleMergeTopic($event, topic, state) {
      const payload = { topic };
      if (state) {
        this.$emit('action', { action: 'mergeRemoveTopic', payload });
      } else {
        this.$emit('action', { action: 'mergeAddTopic', payload });
      }
    },
    gotoDio($event, id) {
      const payload = { id };
      this.$emit('action', { action: 'gotoDio', payload });
    },
    handleSearch() {
      this.$emit('search', { text: this.search });
    },
    showTopicEntry() {
      this.showTopicEntryDialog = true;
    },
    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        quality: '80',
        imageUrl: image,
        gifToImage: true
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },

    handleImageAdd($event, id) {
      const image = $event.target.files[0];
      const payload = { image, id };
      this.$emit('action', { action: 'changeThumbnail', payload });
    },
    changeRoute(query, { reset, replace } = {}) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      let cleanQuery = { query: {} };
      //null ise sil
      Object.keys(newPath.query).forEach(function(key) {
        if (newPath.query[key] !== null) {
          cleanQuery.query[key] = newPath.query[key];
        }
      });
      // router.push ya da router.replace
      const method = replace ? 'replace' : 'push';
      this.$router[method](cleanQuery);
    },

    handleSort(orderKey, _orderState) {
      // todo: null state fix
      const currentQuery = this.$route.query;
      let nextValue = 'asc';

      if (currentQuery.orderValue === 'asc') {
        nextValue = 'desc';
      }
      if (currentQuery.orderValue === 'desc') {
        nextValue = null;
      }
      // order key eşit değilse sıfırla
      if (currentQuery.orderKey !== orderKey) {
        nextValue = 'asc';
      }

      if (!nextValue) {
        this.changeRoute({ orderKey: null, orderValue: null }); // null verirsen null verdiklerini siler
      } else {
        this.changeRoute({ orderKey, orderValue: nextValue });
      }
    }
  }
};
</script>
<style lang="scss">
.topic__image-wrapper {
  position: relative;
  overflow: hidden;
  .con-vs-loading {
    position: absolute;
  }
}
.topic__image-hover {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  &:hover {
    opacity: 1;
  }
}

.vs-dropdown--menu--after {
  display: none;
}
.more-icon {
  width: 24px;
  height: 24px;
}
.vs-dropdown--item-link {
  .feather {
    font-size: 1.2rem;
  }
}
.state-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort-th {
  i {
    display: none !important;
  }
}
</style>
